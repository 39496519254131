*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

html {
  font-family: Space Grotesk, sans-serif;
}

*, :before, :after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

@keyframes blink {
  to {
    visibility: hidden;
  }
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input:-webkit-autofill, input:-webkit-autofill:focus {
  transition: background-color 900000s, color 600000s;
}

body::-webkit-scrollbar {
  width: .5rem;
}

body::-webkit-scrollbar-thumb:active {
  background-color: #131314;
}

body::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
  background: #3b3b46;
  box-shadow: 0 0 2px 1px #0003;
}

body::-webkit-scrollbar-track {
  background: #131314;
}

body::-webkit-scrollbar-track:hover, body::-webkit-scrollbar-track:active {
  background: #131314;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.top-0 {
  top: 0;
}

.z-40 {
  z-index: 40;
}

.z-0 {
  z-index: 0;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.hidden {
  display: none;
}

.h-\[40px\] {
  height: 40px;
}

.h-\[80px\] {
  height: 80px;
}

.h-full {
  height: 100%;
}

.w-screen {
  width: 100vw;
}

.w-full {
  width: 100%;
}

.w-3\/4 {
  width: 75%;
}

.w-1\/5 {
  width: 20%;
}

.w-\[200px\] {
  width: 200px;
}

.shrink-0 {
  flex-shrink: 0;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.snap-x {
  scroll-snap-type: x var(--tw-scroll-snap-strictness);
}

.snap-center {
  scroll-snap-align: center;
}

.list-none {
  list-style-type: none;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-8 {
  gap: 2rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-16 {
  gap: 4rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-12 {
  gap: 3rem;
}

.gap-6 {
  gap: 1.5rem;
}

.overflow-x-auto {
  overflow-x: auto;
}

.scroll-smooth {
  scroll-behavior: smooth;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-full {
  border-radius: 9999px;
}

.border {
  border-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-border {
  border-color: #eeeef614;
}

.border-red\/\[16\%\] {
  border-color: #d2276529;
}

.border-pink\/\[16\%\] {
  border-color: #cf27d229;
}

.border-purple\/\[16\%\] {
  border-color: #9e3eff29;
}

.border-purpleLight\/\[16\%\] {
  border-color: #7777ff29;
}

.bg-background {
  --tw-bg-opacity: 1;
  background-color: rgb(7 7 13 / var(--tw-bg-opacity));
}

.bg-elevation {
  background-color: #101018cc;
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(63 63 255 / var(--tw-bg-opacity));
}

.bg-red\/\[8\%\] {
  background-color: #d2276514;
}

.bg-pink\/\[8\%\] {
  background-color: #cf27d214;
}

.bg-purple\/\[8\%\] {
  background-color: #9e3eff14;
}

.bg-purpleLight\/\[8\%\] {
  background-color: #7777ff14;
}

.p-6 {
  padding: 1.5rem;
}

.p-8 {
  padding: 2rem;
}

.p-4 {
  padding: 1rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.font-medium {
  font-weight: 500;
}

.font-bold {
  font-weight: 700;
}

.font-normal {
  font-weight: 400;
}

.uppercase {
  text-transform: uppercase;
}

.leading-6 {
  line-height: 1.5rem;
}

.tracking-\[0\.32em\] {
  letter-spacing: .32em;
}

.text-caption {
  color: #eeeef6b3;
}

.text-purpleLight {
  --tw-text-opacity: 1;
  color: rgb(119 119 255 / var(--tw-text-opacity));
}

.text-label {
  --tw-text-opacity: 1;
  color: rgb(238 238 246 / var(--tw-text-opacity));
}

.text-placeholder {
  color: #eeeef680;
}

.text-red {
  --tw-text-opacity: 1;
  color: rgb(210 39 101 / var(--tw-text-opacity));
}

.text-pink {
  --tw-text-opacity: 1;
  color: rgb(207 39 210 / var(--tw-text-opacity));
}

.text-purple {
  --tw-text-opacity: 1;
  color: rgb(158 62 255 / var(--tw-text-opacity));
}

.text-blueTitle {
  --tw-text-opacity: 1;
  color: rgb(39 210 200 / var(--tw-text-opacity));
}

.text-greenTitle {
  --tw-text-opacity: 1;
  color: rgb(104 210 39 / var(--tw-text-opacity));
}

.backdrop-blur-xl {
  --tw-backdrop-blur: blur(24px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

@font-face {
}

.hover\:bg-primary\/90:hover {
  background-color: #3f3fffe6;
}

.hover\:bg-label\/\[\.04\]:hover {
  background-color: #eeeef60a;
}

.hover\:text-label:hover, .active\:text-label:active {
  --tw-text-opacity: 1;
  color: rgb(238 238 246 / var(--tw-text-opacity));
}

.active\:text-caption:active {
  color: #eeeef6b3;
}

.group:hover .group-hover\:bg-red\/\[12\%\] {
  background-color: #d227651f;
}

.group:hover .group-hover\:bg-pink\/\[12\%\] {
  background-color: #cf27d21f;
}

.group:hover .group-hover\:bg-purple\/\[12\%\] {
  background-color: #9e3eff1f;
}

.group:hover .group-hover\:bg-purpleLight\/\[12\%\] {
  background-color: #7777ff1f;
}

@media (min-width: 640px) {
  .sm\:block {
    display: block;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:w-auto {
    width: auto;
  }

  .sm\:gap-8 {
    gap: 2rem;
  }

  .sm\:gap-4 {
    gap: 1rem;
  }

  .sm\:gap-20 {
    gap: 5rem;
  }

  .sm\:p-20 {
    padding: 5rem;
  }

  .sm\:p-4 {
    padding: 1rem;
  }

  .sm\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .sm\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .sm\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

@media (min-width: 768px) {
  .md\:flex {
    display: flex;
  }

  .md\:grid {
    display: grid;
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:justify-between {
    justify-content: space-between;
  }

  .md\:justify-evenly {
    justify-content: space-evenly;
  }

  .md\:gap-8 {
    gap: 2rem;
  }

  .md\:p-4 {
    padding: 1rem;
  }

  .md\:p-12 {
    padding: 3rem;
  }

  .md\:text-left {
    text-align: left;
  }

  .md\:text-right {
    text-align: right;
  }

  .md\:font-medium {
    font-weight: 500;
  }
}

@media (min-width: 1024px) {
  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:gap-16 {
    gap: 4rem;
  }

  .lg\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .lg\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

/*# sourceMappingURL=index.44d1068f.css.map */

@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'Space Grotesk', sans-serif;
}

@layer base {
  html {
    font-family: 'Space Grotesk', sans-serif;
  }
}

@layer components {

  .blinking-cursor {
    user-select: none;
    animation: blink 1s steps(2, start) infinite;
  }

  @keyframes blink {
    to {
      visibility: hidden;
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 900000s 0s, color 600000s 0s;
  }
  /* Scroll thumb styles */
  body::-webkit-scrollbar {
    width: .5rem;
  }

  body::-webkit-scrollbar-thumb:active     {
    background-color: #131314;
  }
  body::-webkit-scrollbar-thumb:hover {
    background: #3B3B46;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }

  /* Scroll track styles (Background) */
  body::-webkit-scrollbar-track {
    background: #131314;
  }
  body::-webkit-scrollbar-track:hover,
  body::-webkit-scrollbar-track:active {
    background: #131314;
  }
}